<template>
  <div class="contact flex justify-center">
    <div
      v-if="formSent"
      class="container"
    >
      <div
        class="map-title"
      >{{ $t(thankYou) }}</div>
      <div class="map-text">
        {{ $t('msgSuccessfllySent') }}        <br>
        {{ $t('readMoreSystem') }}
        <router-link
          class="clean-link"
          to="/teacher/knowledge-base"
          @click="!formSent"
        >{{ $t('here') }}</router-link>
      </div>
    </div>
    <div
      v-else
      class="contact-container"
    >
      <div class="edvise-page-title">{{ $t('navbar.contact') }}</div>
      <div class="form-content">

        <div class="contact-us-instructions">{{ $t('contactInstruction.fillAllFields') }}</div>
        <div class="inputs-container">
          <div class="input-container placeholder">
            <div>
              <label
                class="edvise-input-label contact-label"
                for="lastname"
              >{{ $t('labels.lastName') }}</label>
            </div>
            <input
              id="lastname"
              v-model="formData.lastName"
              type="text"
              class="edvise-input contact-input"
              :placeholder="$t('labels.namePlaceholder')"
              @blur="touched.lastName = true"
            >
            <div
              class="validation-text"
              :class="{'show': touched.lastName && !isTextHebrew(formData.lastName) }"
            >
              {{ $t('hebOnly') }}
            </div>
          </div>
          <div class="input-container placeholder">
            <div>
              <label
                class="edvise-input-label contact-label"
                for="firstname"
              >{{ $t('labels.firstName') }}</label>
            </div>
            <input
              id="firstname"
              v-model="formData.firstName"
              type="text"
              class="edvise-input contact-input"
              :placeholder="$t('labels.namePlaceholder')"
              @blur="touched.firstName = true"
            >
            <div
              class="validation-text"
              :class="{'show': touched.firstName && !isTextHebrew(formData.firstName) }"
            >
              {{ $t('hebOnly') }}
            </div>
          </div>
        </div>
        <div class="inputs-container">
          <div class="input-container placeholder">
            <div>
              <label
                class="edvise-input-label contact-label"
                for="phone"
              >{{ $t('labels.phone') }}
              </label>
            </div>
            <!-- TODO: Put a placeholder in the phone number field? -->
            <input
              id="phone"
              v-model="formData.phone"
              type="number"
              class="edvise-input contact-input"
              :placeholder="$t('labels.numPlaceholder')"
              @input="limitPhoneLength"
              @blur="touched.phone = true"
            >
            <div
              class="validation-text"
              :class="{'show': touched.phone && !isPhoneValid() }"
            >
              {{ $t('invalidPhone') }}
            </div>
          </div>
          <div class="input-container placeholder">
            <div>
              <label
                class="edvise-input-label contact-label"
                for="email"
              >{{ $t('labels.email') }}</label>
            </div>
            <input
              id="email"
              v-model="formData.email"
              type="text"
              class="edvise-input contact-input"
              @blur="touched.email = true"
            >
            <div
              class="validation-text"
              :class="{'show': touched.email && !email }"
            >
              {{ $t('invalidEmail') }}
            </div>
          </div>        
        </div>
        <div class="inputs-container">
          <div class="textarea-container placeholder">
            <div>
              <label
                class="edvise-input-label contact-label"
                for="message"
              >{{ $t('labels.message') }}</label>
            </div>
            <textarea
              id="message"
              v-model="formData.message"
              type="textarea"
              rows="10"
              class="edvise-input contact-input"
              :placeholder="$t('labels.messagePlaceholder')"
              maxlength="500"
            />
          </div>
        </div>
      </div>
      <div class="send-btn">
        <button 
          class="search-btn round-btn-blue" 
          :disabled="!isFormValid"
          :class="{ 'disabled-btn': !isFormValid }"
          @click="sendMail"
        > {{ $t('send') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import generalService from '../services/general-service';
import Notifications from '../services/notifications';

export default {
  name: 'Contact',
  data() {
    return {
      formSent: false,
      formData: {
        firstName: null,
        lastName: null,
        phone: null,
        // lastName: null,
        email: null,
        // subject: null,
        message: null
      },
      sendMailMode: 500, // help the component know when the mail is being sent, failed or not initiated.
      //values: 0 = not initiated, 100 = being sent, 200 = sent successfully, 500 = fail
      // isFormValid: false,
      rules: {
        required: value => !!value || '',
        email: () => this.email || ''
      },
      touched: {
        email: false,
        phone: false,
        lastName: false,
        firstName: false,
        message: false
      }
    };
  },
  computed: {
    email() {
      return this.validEmail(this.formData.email);
    },
    isFormValid() {
      return this.validateFormFields();
    }
  },
  // watch: {
  //   $route() {
  //     // console.log('route change');
  //     this.formSent = false;
  //   }
  // },
  methods: {
    limitPhoneLength() {
      if (this.formData.phone > 10) {
        this.formData.phone = this.formData.phone.slice(0, 10);
      }
    },
    validateFormFields() {
      for (let key in this.formData) {
        if (!this.formData[key]) {
          return false;
        }
        if (key === 'lastName' || key === 'fistName') {
          if (!this.isTextHebrew(this.formData[key])) {
            return false;
          }
        }
        if (key === 'email') {
          if (!this.validEmail(this.formData[key])) {
            // console.log('Mail is not valid');
            return false;
          }
        }
        if (key === 'phone') {
          return this.isPhoneValid();
        }
      }
      return true;
    },
    validEmail(email) {
      let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    isTextHebrew(string) {
      // TODO: add to regex: spaces and -
      let re = /^[\u0590-\u05FF]+$/g;
      return re.test(String(string).toLowerCase());
    },
    isPhoneValid() {
      if (!this.formData.phone) {
        return false;
      }
      if (this.formData.phone.match(/^05\d([-]{0,1})\d{7}$/)) {
        return true;
      }
      return false;
    },
    async sendMail() {
      if (this.validateFormFields()) {
        // if ( false ) { // for testing
        // Form data is valid, initialize a request to the server
        let formattedMailData = {
          email: this.formData.email,
          phone: this.formData.phone,
          name: this.formData.firstName + ' ' + this.formData.lastName, // + ' ' + checkedFormData.lastName,
          content: this.formData.message
        };
        this.sendMailMode = 100;
        let response = await generalService.sendContactUsMail(
          formattedMailData
        );
        if (response.success) {
          // console.log('Mail sent successfully.');
          // setTimeout(()=>
          this.sendMailMode = 200;
          // , 1000)
          this.formSent = true;
        } else {
          // console.log('Mail request failed.');
          this.sendMailMode = 500;
        }
      } else {
        // Form data didn't pass the test function
        // console.log('invalid form');
        Notifications.noTitle('warn', this.$t('serverWarn'));
        this.sendMailMode = 500;
      }
    },
    navigateBack() {
      //navigates to last location.
      this.$router.back();
    }
  }
};
</script>

<style lang="scss" scoped>
@import '../assets/scss/style.scss';

.edvise-page-title {
  margin-top: 4rem;
  @media (max-width: 1366px) {
    padding-right: 20px;
  }
}

.form-content {
  @media (max-width: 1366px) {
    max-height: 43vh;
    overflow: auto;
    padding-right: 20px;
  }
}

.map-title {
  font-family: $font-main;
  font-size: 40px;
  font-weight: bold;
  font-style: normal;
  font-stretch: normal;
  line-height: 0.96;
  letter-spacing: normal;
  color: $color-blue;
  padding: 0rem 1rem 2rem 0rem;
  margin: 0;
  text-align: right;
}
.clean-link {
  color: $color-blue;
  text-decoration: underline;
}
.container {
  margin: 0 auto;
  padding: 4rem 4rem 4rem 0rem;
  width: 48rem;
  font-family: $font-main;
}
.map-text {
  font-size: 16px;
  letter-spacing: normal;
  text-align: right;
  padding: 1rem 1rem 0 0;
  color: $color-blue;
  direction: rtl;
}
.contact {
  text-align: right;

  .contact-container {
    width: 65%;
  }

  .inputs-container {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    margin-top: 1em;
  }

  .textarea-container {
    width: 100%;
  }

  .input-container {
    // width: 50%;
    // display: inline-block;
  }
  // .contact-us-instructions {
  //   margin-bottom: 1.5em;
  // }

  .contact-input {
    direction: rtl;
    padding: 22px 10px;
    font-size: 16px;
    // width: 90%;
    width: 315px;
  }

  #message.contact-input {
    width: 100%;
    padding: 10px;
    height: 56px;
    line-height: 32px;
    font-size: 16px;
    resize: none;
  }
}

.send-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 5vh;
  @media (max-width: 1366px) {
    padding-right: 20px;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

.validation-text {
  text-align: left;
  color: red;
  font-weight: 600;
  font-size: 16px;
  visibility: hidden;

  &.show {
    visibility: initial;
  }
}

.disabled-btn {
  cursor: auto;
}

.contact-label {
  font-size: 16px;
  line-height: 3vh;
}
</style>
